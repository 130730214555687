import { Flex, IconButton } from "@radix-ui/themes";
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";

type PaginationProps = {
    count: number; // total number of pages
    page: number; // current page
    onChange: (event: React.MouseEvent, page: number) => void;
};

export const Pagination = ({ count, page, onChange }: PaginationProps) => {
    const handlePrev = (e: React.MouseEvent) => {
        if (page > 1) {
            onChange(e, page - 1);
        }
    };

    const handleNext = (e: React.MouseEvent) => {
        if (page < count) {
            onChange(e, page + 1);
        }
    };

    const renderPageNumbers = () => {
        const pages = [];
        const delta = 1; // number of neighbors to show around the current page

        const range: (number | { type: "ellipsis"; page: number })[] = [];
        const left = Math.max(2, page - delta);
        const right = Math.min(count - 1, page + delta);

        range.push(1); // always show the first page

        if (left > 2) {
            range.push({ type: "ellipsis", page: Math.floor((1 + left) / 2) });
        }

        for (let i = left; i <= right; i++) {
            range.push(i);
        }

        if (right < count - 1) {
            range.push({ type: "ellipsis", page: Math.floor((right + count) / 2) });
        }

        if (count > 1) {
            range.push(count); // always show the last page
        }

        for (const item of range) {
            if (typeof item === "number") {
                const isActive = item === page;
                pages.push(
                    <IconButton
                        key={item}
                        variant={isActive ? "solid" : "outline"}
                        onClick={(e) => onChange(e, item)}
                        size="2"
                    >
                        {item}
                    </IconButton>
                );
            } else {
                // Render clickable ellipsis as a button
                pages.push(
                    <IconButton
                        key={`ellipsis-${item.page}`}
                        variant="ghost"
                        style={{padding: "4px 8px"}}
                        onClick={(e) => onChange(e, item.page)}
                        size="2"
                        aria-label={`Jump to page ${item.page}`}
                    >
                        …
                    </IconButton>
                );
            }
        }

        return pages;
    };



    return (
        <Flex gap="2" align="center">
            <IconButton
                variant="soft"
                disabled={page === 1}
                onClick={handlePrev}
                size="2"
            >
                <ChevronLeftIcon />
            </IconButton>

            {renderPageNumbers()}

            <IconButton
                variant="soft"
                disabled={page === count}
                onClick={handleNext}
                size="2"
            >
                <ChevronRightIcon />
            </IconButton>
        </Flex>
    );
};

