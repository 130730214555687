import * as React from "react";
import {useAppState} from "../../state/AppStateProvider";
import useSWR from "swr";
import {getPointsLeaderboard, getReferralInfo, getUserPointsBalance} from "../../api/hasura";
import {Flex, Text, Button, IconButton, Table, Card, Spinner, Tooltip} from "@radix-ui/themes";
import {CopyIcon, InfoCircledIcon, OpenInNewWindowIcon} from "@radix-ui/react-icons";
import {LDKeyPointsReferral, openAlmanakDocs} from "../../components/layout/Sidebar";
import {UserProfileLink} from "./PointsProfileLink";
import {PageContentContainer} from "../styled/styled";
import {Pagination} from "../../components/Pagination";


// Leaderboard interfaces
interface LeaderboardEntry {
    nameService: string | null;
    points: number;
    rank: number;
    referrerNameService: string | null;
    referrerWalletAddress: string | null;
    walletAddress: string;
}

interface PaginationData {
    currentPage: number;
    totalItems: number;
    totalPages: number;
}

interface LeaderboardData {
    message: string;
    success: boolean;
    data: {
        pagination: PaginationData;
        results: LeaderboardEntry[];
    };
}

const formatNumberWithCommas = (num: number) => {
    if (!num) return "0";
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const UserPoints = () => {
    const {featureFlags, user, setSnackBar, theme} = useAppState();

    const [currentPage, setCurrentPage] = React.useState(1);

    const {
        data: userPoints,
        isLoading: isUserPointsLoading,
    } = useSWR(user ? "user-points-balance" : null, () => getUserPointsBalance(user?.id));

    const {
        data: referralInfo
    } = useSWR(user ? `referral-info-${user.id}` : null, getReferralInfo);

    const {
        data: leaderboardData,
        isLoading: isLeaderboardLoading,
        error: leaderboardError,
    } = useSWR<LeaderboardData>(
        ["points-leaderboard", currentPage],
        () => getPointsLeaderboard(currentPage),
        {revalidateOnFocus: false}
    );

    const handlePageChange = (page: number): void => {
        setCurrentPage(page);
    };

    const [leaderboard, pagination] = React.useMemo(() => [
        leaderboardData?.data,
        leaderboardData?.data?.pagination
    ], [leaderboardData]);

    const isReferralEnabled = featureFlags?.[LDKeyPointsReferral];
    const inviteCode = referralInfo?.invite_code;
    const referralCount = referralInfo?.referral_count;
    const referralPoints = referralInfo?.referral_points;
    const referralLink = `${window.location.origin}/invite?code=${inviteCode}`;

    return (
        <PageContentContainer>
            <Flex direction="column" gap="6" py="6">
                <Flex direction="column" gap="2">
                    <Flex justify="between" align="center">
                        <Text size="6" weight="bold">Almanak Points</Text>
                        <Button variant="ghost" onClick={() => openAlmanakDocs("docs/points")}>
                            Learn more <OpenInNewWindowIcon/>
                        </Button>
                    </Flex>

                    <Text size="4" color="gray">Points are rewarded for using the Almanak Platform.</Text>

                </Flex>
                {user && !isUserPointsLoading ? <Flex gap="4" wrap="wrap">
                    <StatsCard
                        title="Your Points"
                        value={isUserPointsLoading ? "Loading..." : formatNumberWithCommas(userPoints)}
                        description="Total points earned"
                        explanation={"Including points earned from referrals"}
                    />
                    {isReferralEnabled && (
                        <>
                            <StatsCard
                                title="Referred Users"
                                value={referralCount ?? "0"}
                                description="People who joined using your link"
                            />
                            <StatsCard
                                title="Referral Points"
                                value={formatNumberWithCommas(referralPoints ?? 0)}
                                description="Points from referrals only"
                            />
                        </>
                    )}
                </Flex> : <Flex align={"center"} gap={"4"}><Spinner/> Loading user points...</Flex>}

                {isReferralEnabled && (
                    <Flex direction="column" gap="2">
                        <Text size="5" weight="medium">Your Referral Link</Text>
                        <Flex gap="2">
                            <Text
                                as="span"
                                size="2"
                                style={{
                                    padding: "8px 12px",
                                    backgroundColor: theme === "dark" ? "gray" : "#f9f9f9",
                                    borderRadius: "4px",
                                    fontFamily: "monospace",
                                    wordBreak: "break-all",
                                }}
                            >
                                {referralLink}
                            </Text>
                            <IconButton
                                size="2"
                                variant="outline"
                                onClick={() => {
                                    navigator.clipboard.writeText(referralLink);
                                    setSnackBar({
                                        open: true,
                                        message: "Referral link copied to clipboard",
                                        severity: "success",
                                    });
                                }}
                            >
                                <CopyIcon/>
                            </IconButton>
                        </Flex>
                    </Flex>
                )}

                <Text size="5" weight="bold">Leaderboard</Text>

                {isLeaderboardLoading ? (
                    <Flex align={"center"} gap={"4"}><Spinner/> Loading Leaderboard</Flex>
                ) : leaderboardError ? (
                    <Text color="red">Failed to load leaderboard.</Text>
                ) : (
                    <Table.Root variant="surface" size="2">
                        <Table.Header>
                            <Table.Row>
                                <Table.ColumnHeaderCell>Rank</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>User</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Points</Table.ColumnHeaderCell>
                                {isReferralEnabled && (
                                    <Table.ColumnHeaderCell>Invited By</Table.ColumnHeaderCell>
                                )}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {leaderboard?.results?.map((entry: any) => (
                                <Table.Row key={entry.walletAddress}>
                                    <Table.RowHeaderCell>
                                        {entry.rank <= 3 ? (
                                            <Text size="7">
                                                {entry.rank === 1 ? "🥇" : entry.rank === 2 ? "🥈" : "🥉"}
                                            </Text>
                                        ) : (
                                            <Text size={"3"} style={{marginLeft: "8px"}}>{entry.rank}</Text>
                                        )}
                                    </Table.RowHeaderCell>

                                    <Table.Cell>
                                        <Flex align="center" gap="2">
                                            <UserProfileLink
                                                walletAddress={entry.walletAddress}
                                                nameService={entry.nameService}
                                            />
                                        </Flex>
                                    </Table.Cell>

                                    <Table.Cell>{formatNumberWithCommas(entry.points)}</Table.Cell>

                                    {isReferralEnabled && (
                                        <Table.Cell>
                                            <UserProfileLink
                                                walletAddress={entry.referrerWalletAddress}
                                                nameService={entry.referrerNameService}
                                            />
                                        </Table.Cell>
                                    )}
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table.Root>
                )}

                {pagination && pagination.totalPages > 1 && (
                    <Flex justify="center" mt="4">
                        <Pagination
                            count={pagination.totalPages}
                            page={pagination.currentPage}
                            onChange={(_, page) => handlePageChange(page)}
                        />
                    </Flex>
                )}

            </Flex>
        </PageContentContainer>
    );
};

function StatsCard({title, value, description, explanation}: { title: string, value: string, description: string, explanation?: string }) {
    return (
        <Card style={{padding: "16px", minWidth: 220, flex: 1}}>
            <Flex direction="column" justify="between" gap="1">
                <Flex align={"center"}>
                    <Text size="2" color="gray">{title}</Text>
                    {explanation && <Tooltip content={explanation}><InfoCircledIcon style={{marginLeft: "4px"}} /></Tooltip>}
                </Flex>
                <Text size="6" weight="bold">{value}</Text>
                <Text size="1" color="gray">{description}</Text>
            </Flex>
        </Card>
    );
}
